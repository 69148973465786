// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("stylesheets")
require("@popperjs/core")
require("../stylesheets/application.scss")

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@popperjs/core/"
import "jquery"
import "bootstrap"
import "@fortawesome/fontawesome-free/js/all"
import "@fortawesome/fontawesome-free/css/all"
import flatpickr from "flatpickr"
global.toastr = require("toastr")
import '@client-side-validations/simple-form/src/index.bootstrap4'
require('@client-side-validations/simple-form/dist/simple-form.bootstrap4.esm')

Rails.start()
Turbolinks.start()
ActiveStorage.start()
